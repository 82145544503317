import { React, useState, useEffect } from 'react';// adjust this import path to where your JSONTableEditor file is located
import { Dialog, Select, MenuItem, Grid, DialogActions, TextField, DialogContent, DialogContentText, DialogTitle, Button, IconButton, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import GenericInsert from '../GenericInsert';
import DynamicForm from '../DynamicForm';
import JSONTableEditor from '../JSONTableEditor';
import CentralCalls from '../../centralCalls';
import { useTheme } from '@mui/styles';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'react-modal';
import DeleteConfirmationPopup from '../DeleteConfirmationPopup';

const useStyles = makeStyles(theme => ({
    customHeader: {
        borderRadius: '10px',
        fontSize: 'large',
        padding: '0px',
        margin: '0px',
        border: 'black solid'
    }
}))

const MaterialModel = () => {
    //const [templates, setTemplates] = useState(null);
    const [openNewMaterial, setOpenNewMaterial] = useState(false);
    //const [selectedTemplateOid, setSelectedTemplateOid] = useState(0);
    //const [selectedCopyTemplateOid, setSelectedCopyTemplateOid] = useState(0);
    //const [copyTemplateName, setCopyTemplateName] = useState('');
    // const handleTemplateChange = (event) => {
    //     setSelectedTemplateOid(event.target.value);
    // };
    // useEffect(() => {
    //     CentralCalls.fetchTableDataNoWhere('material_template').then(response => setTemplates(response));
    // }, []);
    const handleAddMaterialPostRun = async (templateRow) => {
        setOpenNewMaterial(false);
    }
    const handleSave = async (row) => {

    }

    const procedureDetails = {
        procedureName: 'ui_spGetMaterialList',
        parameters: { crud_type: 'update' },  // If there are any parameters, define them here as an object
    };
    // const procedureDetailsInsert = {
    //     procedureName: 'ui_sp_getTemplatePageDataInsert',
    //     parameters: { material_template_oid: selectedTemplateOid, crud_type: 'insert' },  // If there are any parameters, define them here as an object
    // };
    return (
        <>
            <h2>Materials                             
            <Tooltip title="Add Template">
                <IconButton color="primary" aria-label="save" onClick={() => setOpenNewMaterial(true)} size="medium">
                    <FontAwesomeIcon icon={faPlus} />
                </IconButton>
            </Tooltip></h2>
            {/* {selectedTemplateOid !== null && selectedTemplateOid > 0 && (
                <DynamicForm procedureDetails={procedureDetailsInsert}
                    onSave={handleCompleteAddPresetPost}
                    saveName='Insert'
                    crudType='insert' />
            )} */}

            <JSONTableEditor procedureDetails={procedureDetails} onSave={handleSave} sortOrder={{ 'enabled': 'desc' }} allowDelete={true} ></JSONTableEditor>
            <Dialog fullWidth={true} maxWidth='lg' open={openNewMaterial} onClose={() => setOpenNewMaterial(false)}>
                <DialogContent>
                    <GenericInsert key={'/materialModel'} displayName={'New Material'} tableName={'material_model'} onInsert={handleAddMaterialPostRun} />   
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenNewMaterial(false)} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
export default MaterialModel;
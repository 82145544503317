import React, { useState, useEffect } from 'react';
import './LotFilter.css'; // Import the CSS file for component styling
import CentralCalls from '../../centralCalls';
import { CircularProgress, Dialog, DialogActions, DialogContent, FormControl, TextField, IconButton, EventIcon, Button, Select, MenuItem } from '@mui/material';
import { useLocation } from 'react-router-dom';
import Autocomplete from '@mui/material/Autocomplete';
import AddIcon from '@mui/icons-material/Add';
import GenericInsert from '../GenericInsert';

const MaterialSelector = ({ onMaterialModelChange, lot_oid }) => {
    const [materialTypes, setMaterialTypes] = useState([]);
    const [selectedMaterialType, setSelectedMaterialType] = useState(null);
    const [materialDescs, setMaterialDescs] = useState([]);
    const [selectedMaterialDesc, setSelectedMaterialDesc] = useState('');
    const [materialModels, setMaterialModels] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedMaterialModel, setSelectedMaterialModel] = useState('');
    const [showInsertDialog, setShowInsertDialog] = useState(false);
    const [dialogComponentType, setDialogComponentType] = useState('material_type');
    const [existingRooms, setExistingRooms] = useState([]);
    const [selectedRoom, setSelectedRoom] = useState(0);
    // Inside your LotFilter component
    const location = useLocation();
    // Function to parse query parameters
    const useQuery = () => {
        return new URLSearchParams(location.search);
    };
    const query = useQuery();
    const modelOidFromUrl = query.get('model_oid');
    // Fetch materialTypes from API
    useEffect(() => {
        if (!modelOidFromUrl) {
            loadMaterialTypes();
        }
    }, [modelOidFromUrl]);
    useEffect(() => {
        const getRoomList = async () => {
            if (lot_oid) {
                var rooms = await CentralCalls.callStoredProcedureWithParams('sp_getExistingRoomsPerLot', { 'lot_oid': lot_oid });
                setExistingRooms(rooms);
            }
        }
        //var rooms = CentralCalls.callStoredProcedureWithParams('sp_getExistingRoomsPerLot', {'lot_oid' : lot_oid});
        getRoomList();
    }, [lot_oid])
    useEffect(() => {
        const fetchInitialData = async () => {
            if (modelOidFromUrl) {
                try {
                    setLoading(true);
                    const rows = await CentralCalls.fetchTableData('material_model', { oid: modelOidFromUrl });
                    if (rows && rows.length > 0) {                        
                        const { material_type_oid } = rows[0];
                        await loadMaterialTypes();
                        setSelectedMaterialType(material_type_oid);
                        //await loadMaterialDescs(material_type_oid);
                        //setSelectedMaterialDesc(material_desc_oid);
                        await loadMaterialModels(material_type_oid);
                        setSelectedMaterialModel(modelOidFromUrl);
                        onMaterialModelChange(modelOidFromUrl);
                    }
                    setLoading(false);
                } catch (error) {
                    console.error('Failed to fetch initial data:', error);
                }

            }
            console.log('lading gfalse..')
            setLoading(false);
        };
        fetchInitialData();
    }, [modelOidFromUrl]);
    const loadMaterialTypes = async () => {
        const rows = await CentralCalls.fetchTableData('material_type', {});
        setMaterialTypes(rows);
    };
    const handleAddMaterialTypePostRun = async (materialTypeRow) => {
        const updatedMaterialTypes = [...materialTypes, { oid: materialTypeRow.oid, type: materialTypeRow.type }];
        await setMaterialTypes(updatedMaterialTypes);
        await setShowInsertDialog(false);
        setSelectedMaterialType(materialTypeRow.oid);
    }
    const handleAddMaterialDescPostRun = async (materialDescRow) => {
        const updatedMaterialDescs = [...materialDescs, { oid: materialDescRow.oid, desc: materialDescRow.desc }];
        await setMaterialDescs(updatedMaterialDescs);
        await setShowInsertDialog(false);
        setSelectedMaterialDesc(materialDescRow.oid);
    }
    const handleAddMaterialModelPostRun = async (materialModelRow) => {
        const updatedMaterialModels = [...materialModels, { oid: materialModelRow.oid, model_number: materialModelRow.model_number }];
        await setMaterialModels(updatedMaterialModels);
        await setShowInsertDialog(false);
        setSelectedMaterialDesc(materialModelRow.oid);
    }
    const renderDialogComponent = () => {
        switch (dialogComponentType) {
            case 'material_type':
                return <GenericInsert key={'/materialType'} displayName={'Fixture'} tableName={'material_type'} onInsert={handleAddMaterialTypePostRun} />;
            case 'material_desc':
                return <GenericInsert key={'/materialDesc'} displayName={'Description And Model'} tableName={'material_desc'} onInsert={handleAddMaterialDescPostRun} defaults={[{ 'name': 'material_type_oid', 'value': selectedMaterialType, 'enabled': false }]} />;
            case 'material_model':
                return <GenericInsert key={'/materialModel'} displayName={'Model Number'} tableName={'material_model'} onInsert={handleAddMaterialModelPostRun} defaults={[{ 'name': 'material_type_oid', 'value': selectedMaterialType, 'enabled': false }]} />;
            default:
                return null;
        }
    };

    const handleMaterialTypeChange = (oid) => {
        setSelectedMaterialType(oid);
        setSelectedMaterialDesc(null);
        setSelectedMaterialModel(null);
        if (oid) {
            loadMaterialModels(oid);
        }
    };
    const openAddDialog = async (componentType) => {
        await setDialogComponentType(componentType);
        setShowInsertDialog(true);
    }
    // const loadMaterialDescs = async (material_type_oid) => {
    //     const rows = await CentralCalls.fetchTableData('material_desc', { 'material_type_oid': material_type_oid });
    //     setMaterialDescs(rows);
    // }
    const loadMaterialModels = async (material_type_oid) => {
        const rows = await CentralCalls.fetchTableData('material_model', { 'material_type_oid': material_type_oid });
        setMaterialModels(rows);
    }

    const handleMaterialDescChange = (oid) => {
        setSelectedMaterialDesc(oid);
        setSelectedMaterialModel(null);
        if (oid) {
            loadMaterialModels(oid);
        }
    };
    const handleMaterialModelChange = (oid) => {
        setSelectedMaterialModel(oid);
        onMaterialModelChange({model_oid: oid, room_oid: selectedRoom });
    };
    return (
        <>
            <div className="lot-filter-container">
                {loading ? (
                    <CircularProgress />
                ) : (

                    <div className="lot-filter">
                        {/* <Select value={selectedRoom} onChange={(event) => setSelectedRoom(event.target.value)}>
                        <MenuItem key={0} value={''}>
                                        Select
                                    </MenuItem>
                            {existingRooms
                                .slice()
                                .sort((a, b) => a.name.localeCompare(b.name)) // Sort options inline
                                .map((option) => (
                                    <MenuItem key={option.oid} value={option.oid}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                        </Select> */}
                       
                        <Autocomplete
                            options={existingRooms?.sort((a, b) => a.name.localeCompare(b.name)) || []}
                            getOptionLabel={(option) => option.name || ''}
                            
                            style={{ width: '100%' }}
                            onChange={(event, newValue) => setSelectedRoom(newValue ? newValue.oid : null)}
                            value={existingRooms?.find(mt => mt.oid === selectedRoom) || null}
                            renderInput={(params) => (
                                <FormControl fullWidth required={true}>
                                    <TextField
                                        {...params}
                                        label={'Room'}
                                        variant="standard"
                                        required={true}
                                    />
                                </FormControl>
                            )}
                        /> 
                        <Autocomplete
                            disabled={selectedRoom === 0}
                            options={materialTypes.sort((a, b) => a.type.localeCompare(b.type)) || []}
                            getOptionLabel={(option) => option.type || ''}
                            
                            style={{ width: '100%' }}
                            onChange={(event, newValue) => handleMaterialTypeChange(newValue ? newValue.oid : null)}
                            value={materialTypes.find(mt => mt.oid === selectedMaterialType) || null}
                            renderInput={(params) => (
                                <FormControl fullWidth required={true}>
                                    <TextField
                                        {...params}
                                        label={'Fixture'}
                                        variant="standard"
                                        required={true}
                                    />
                                </FormControl>
                            )}
                        />
                        <IconButton onClick={() => openAddDialog('material_type')}>
                            <AddIcon />
                        </IconButton>
                     
                       
                        <span className="marker">>></span>
                        <Autocomplete
                            options={materialModels.sort((a, b) => a.model_number.localeCompare(b.model_number)) || []}
                            getOptionLabel={(option) => option.description || ''}
                           
                            style={{ width: '100%' }}
                            onChange={(event, newValue) => handleMaterialModelChange(newValue ? newValue.oid : null)}
                            value={materialModels.find(mt => mt.oid === selectedMaterialModel) || null}
                            renderInput={(params) => (
                                <FormControl fullWidth required={true}>
                                    <TextField
                                        {...params}
                                        label={'Description'}
                                        variant="standard"
                                        required={true}
                                    />
                                </FormControl>
                            )}
                        />
                        <IconButton onClick={() => openAddDialog('material_model')}>
                            <AddIcon />
                        </IconButton>
                    </div>
                )}
            </div>

            <Dialog fullWidth={true} maxWidth='lg' open={showInsertDialog} onClose={() => setShowInsertDialog(false)}>
                <DialogContent style={{ overflow: 'visible' }}>
                    {renderDialogComponent()}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowInsertDialog(false)} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

        </>
    );
}

export default MaterialSelector;
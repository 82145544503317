import React, { useState, useEffect, useMemo } from 'react';
import { IconButton, Dialog, DialogTitle, DialogContent, Button, DialogActions } from '@mui/material';
import { faPlus, faEdit, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DynamicForm from '../DynamicForm';
import CentralCalls from '../../centralCalls';
import './WipTabs.css';
import { Auth } from 'aws-amplify';
import DisplayTable from '../displayTable/DisplayTable';
const WipPurchaseView = ({ selectedLotOid }) => {
    const [lotRow, setLotRow] = useState([]);
    const procedureDetails = useMemo(() => ({
        procedureName: 'ui_sp_wipLotPurchasing',
        parameters: { 'lot_oid': selectedLotOid },
    }), [selectedLotOid]);
    

    useEffect(() => {
        async function setUserInfo() {
            try {
                const user = await Auth.currentAuthenticatedUser();
                //setUserName(user.attributes.email);
            } catch (error) {
                console.error('Error fetching user info', error);
            }
        }
        setUserInfo();
    }, []);

    useEffect(() => {
        async function setUserInfo() {
            try {
                if(selectedLotOid){
                    console.log("running lots")
                    var lotRow = await CentralCalls.fetchTableData('lot', {oid:selectedLotOid});
                    setLotRow(lotRow);
                }
            } catch (error) {
                console.error('Error fetching user info', error);
            }
        }
        setUserInfo();
    }, [selectedLotOid]);
    const onLotUpdate = () => {}
    return (
        <>
            <h2>Purchasing</h2>
            {selectedLotOid && lotRow ? (
               <div style={{paddingLeft:'25px'}}>                    
                    <DynamicForm procedureDetails={procedureDetails} displayOnly={true} onSave={onLotUpdate} saveName={'Update'} crudType={'update'} ></DynamicForm>
               </div>
            ) : (
                <p>Select a lot</p>
            )}
        </>
    );
}

export default WipPurchaseView;

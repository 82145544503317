import { React, useState, useEffect } from 'react';
import JSONTableEditor from '../JSONTableEditor';
import DynamicForm from '../DynamicForm';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, IconButton, Tooltip, MenuItem, Select } from '@mui/material';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { makeStyles } from '@mui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LotFilter from '../workInProgress/LotFilter';
import { useTheme } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import CentralCalls from '../../centralCalls';
import ChangePurchaseStatus from './ChangePurchaseStatus';
import { DateRangePicker } from 'react-date-range';
import { enUS } from 'date-fns/locale';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { format } from 'date-fns';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
        marginBottom: theme.spacing(3),
    },
    select: {
        minWidth: 200,
    },
    accordion: {
        borderRadius: '15px',
        margin: '10px 0',
        '&:before': {
            display: 'none',
        },
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:after': {
            display: 'none',
        },
    },
    accordionSummary: {
        backgroundColor: 'rgba(0, 0, 0, .03)',
    },
}));
const PurchaseReady = ({currentOrderStatus,currentOrderStatusDisplay, newOrderStatus, orderStatusDisplay}) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [tableKey, setTableKey] = useState(1);
    const [showChangeStatus, setShowChangeStatus] = useState(false);
    const [dateRangeDialogOpen, setDateRangeDialogOpen] = useState(false);
    const [selectedStatusChangeLot, setSelectedStatusChangeLot] = useState(false);
    const [startDate, setStartDate] = useState(new Date(new Date().setDate(new Date().getDate() - 30)));
    const [endDate, setEndDate] = useState(new Date());
    const handleOpenPhaseChange = async (orderRow) => {
        setShowChangeStatus(true);
        setSelectedStatusChangeLot(orderRow.lot_oid);
    };
    const handlePostUpdate = async (orderStatusLotRow) => {
        setShowChangeStatus(false);
    }
    const procedureDetails = {
        procedureName: 'ui_sp_getPurchasingReadyToOrder',
        parameters: { order_type: currentOrderStatus, start_date : CentralCalls.sqlFormatdate(startDate), end_date: CentralCalls.sqlFormatdate(endDate) }
    };
    // const procedurePullDetails = {
    //     procedureName: 'ui_sp_getPurchasingReadyToOrder',
    //     parameters: { order_type: currentOrderStatus }
    // };
    const handleWipLinkClick = async (phaseRow) => {
        const path = '/wip?lot_oid=' + phaseRow.lot_oid;
        navigate(path);
      }
    const checkDisplayUndo = (lotRow) => {
        if(currentOrderStatus === 'open' || currentOrderStatus === 'deleted') {
            return false;
        }else{
            return true;
        }
    }
    const checkDisplayMarkComplete = (lotRow) => {
        if(currentOrderStatus === 'complete' || currentOrderStatus === 'deleted') {
            return false;
        }else{
            return true;
        }
    }
    const handleUndoClick = async (phaseRow) => {

        if(currentOrderStatus === 'ordered') {
            await CentralCalls.upsertData({oid: phaseRow.oid, 'order_status': 'open'}, 'lot', 'update')
        }
        else if(currentOrderStatus === 'complete'){
            await CentralCalls.upsertData({oid: phaseRow.oid, 'order_status': 'ordered'}, 'lot', 'update')
        }
        setTableKey(tableKey + 1);
      }
    const handleDateChange = (item) => {
        const newStartDate = item.selection.startDate;
        const newEndDate = item.selection.endDate;
        setStartDate(newStartDate);
        setEndDate(newEndDate);
        // Now using the newStartDate and newEndDate directly
        //onFilterSelect({ filter: activeFilter, startDate: newStartDate, endDate: newEndDate });
      };
    const handleRevertDeleteClick = async (phaseRow) => {
        await CentralCalls.upsertData({oid: phaseRow.oid, 'order_deleted': 0}, 'lot', 'update');
        setTableKey(tableKey + 1);
    }
    const checkDeleteRevert = async (phaseRow) => {
        console.log(currentOrderStatus);
        console.log('in order status')
        if(currentOrderStatus === 'delete'){
            console.log("showing revert...")
            return true;
        }else{
            console.log("hiding revert...")
            return false;
        }
    }
    const formatDate = (date) => {
        if (!date) return '';
        return format(date, 'MM/dd/yyyy');
      };
    const customFunctions = [
        {
            display_name: 'Mark Ordered',
            type: 'check', // type must correspond to a FontAwesome icon name
            func: handleOpenPhaseChange,
            //displayCheck: checkDisplayMarkComplete
        },
        {
            display_name: 'WIP',
            type: 'overview',
            func: handleWipLinkClick
          },
          {
            display_name: 'Undo',
            type: 'revert',
            func: handleUndoClick,
           // displayCheck: checkDisplayUndo
          },
          {
            display_name: 'Restore',
            type: 'revert',
            func: handleRevertDeleteClick,
           // displayCheck: checkDeleteRevert
          }
        ]
    const filteredCustomFunctions = customFunctions.filter((func) => {
            // Exclude 'Undo' when currentOrderStatus is 'deleted'
        if (func.display_name === 'Undo' && (currentOrderStatus === 'deleted' || currentOrderStatus === 'open')) {
            return false;
        }
        if (func.display_name === 'Mark Ordered' && 
            (currentOrderStatus === 'complete' || currentOrderStatus === 'deleted')) {
          return false;
        }
        
        // Exclude 'Undo' if currentOrderStatus is 'open' or 'deleted'
        if (func.display_name === 'Restore' && 
            (currentOrderStatus !== 'deleted')) {
          return false;
        }
            // Include all other functions
        return true;
    });
    return (
        <>
        <h3>{currentOrderStatusDisplay}</h3>
        {currentOrderStatus === 'complete' ? (
      <>
        <IconButton aria-label="save" onClick={() => setDateRangeDialogOpen(true)} size="medium">
          <FontAwesomeIcon icon={faCalendar} />
        </IconButton>
        {formatDate(startDate)} - {formatDate(endDate)}
        <br/>
      </>
    ) : null}
        <JSONTableEditor procedureDetails={procedureDetails} hideEdit={true} customFunctions={filteredCustomFunctions}
                sortOrder={{
                    'material_manufacturer': 'desc',
                    'material_type': 'asc',
                  }}
                  key={{tableKey}}
                  allowDelete={currentOrderStatus === 'deleted' ? false : true}
                  delete_column={'order_deleted'} />
            <Dialog fullWidth={true} maxWidth='lg' open={showChangeStatus} onClose={() => setShowChangeStatus(false)}>   
                <DialogContent >
                    <ChangePurchaseStatus handlePostUpdate={handlePostUpdate} lot_oid={selectedStatusChangeLot} newOrderStatus={newOrderStatus} orderStatusDisplay={orderStatusDisplay} currentOrderStatus={currentOrderStatus} currentOrderStatusDisplay={currentOrderStatusDisplay} ></ChangePurchaseStatus>        
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowChangeStatus(false)} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={dateRangeDialogOpen}  onClose={() => setDateRangeDialogOpen(false)}>
        <DialogContent  style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '400px', 
            marginLeft: '-250px'// Adjust height based on your needs
          }} >
        <DateRangePicker
          onChange={handleDateChange}
          showSelectionPreview={true}
          moveRangeOnFirstSelection={false}
          months={1}
          direction="horizontal"
          locale={enUS}
          ranges={[{
            startDate: startDate,
            endDate: endDate,
            key: 'selection',
          }]}
          inputRanges={[]}
        />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDateRangeDialogOpen(false)}>Ok</Button>
        </DialogActions>
      </Dialog>
        </>
    );
};

export default PurchaseReady;
import React, { useState, useEffect } from 'react';
import {
  Checkbox,
  FormControlLabel,
  Button,
  TextField,
  Grid,
  Select,
  FormLabel,
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
  CircularProgress,
  IconButton, Dialog, DialogActions, DialogContent, DialogTitle
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Autocomplete from '@mui/material/Autocomplete';
import { useTheme } from '@mui/styles';
import Input from '@mui/material/Input';
import Chip from '@mui/material/Chip';
import CentralCalls from '../centralCalls';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import EventIcon from '@mui/icons-material/Event';
import AddIcon from '@mui/icons-material/Add';
import GenericInsert from './GenericInsert';
const useStyles = makeStyles((theme) => ({
  header: {
    marginBottom: theme.spacing(2),
    fontWeight: 'bold',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  fieldContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  control: {
    flex: 1, // Makes sure the control takes up the maximum available space
  },
}));

const DynamicForm = ({ procedureDetails, onSave, saveName, crudType, displayOnly = false }) => {

  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({});
  const [columns, setColumns] = useState([]);
  const [options, setOptions] = useState([]);
  const [tableName, setTableName] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogComponentType, setDialogComponentType] = useState(null);
  const [theme, setTheme] = useState({});

  useEffect(() => {
    fetchData();
  }, [procedureDetails]);

  const fetchData = () => {
    const { procedureName, parameters } = procedureDetails;
    // console.log(procedureName);
    // console.log(parameters);
    const apiUrl =
      parameters && Object.keys(parameters).length > 0
        ? '/api/call-stored-procedureWithParam3'
        : '/api/call-stored-procedure';

    setLoading(true);
    fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ procedureName, param: JSON.stringify(parameters) }),
    })
      .then((response) => response.json())
      .then((result) => {
        //console.log(result);
        const filteredColumns = result.columns;
        setColumns(sortColumnOrder(filteredColumns));
        setTheme(result.theme);
        if (result.options) {
          setOptions(result.options);
        }

        let initialFormData = {};
        // If the response data is not null, undefined or empty, populate formData with corresponding values or default values
        if (result.data && result.data.length > 0) {
          // filteredColumns.forEach((column) => {
          //   initialFormData[column.name] = result.data[0][column.name] !== undefined ? result.data[0][column.name] : column.default || '';
          // });
          filteredColumns.forEach((column) => {
            if (result.data[0][column.name] !== undefined && result.data[0][column.name] !== null) {
              initialFormData[column.name] = result.data[0][column.name];
            } else {
              initialFormData[column.name] = column.default || '';
            }
          });

        } else {
          // If response data is empty, just populate formData with default values
          filteredColumns.forEach((column) => {
            initialFormData[column.name] = column.default || '';
          });
        }
        setFormData(initialFormData);
        setTableName(result.table_name);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        console.log();
        setLoading(false);
        alert('Error Inserting data. Check for duplicate lot number otherwise reach out.')
      });
  };
  const sortColumnOrder = (columns) => {
    columns.sort((a, b) => {
      if (a.column_sort === undefined && b.column_sort === undefined) return 0;  // If both don't have the key, order remains unchanged.
      if (a.column_sort === undefined) return 1;  // If a doesn't have the key, it goes to the end.
      if (b.column_sort === undefined) return -1; // If b doesn't have the key, it goes to the end.
      return a.column_sort - b.column_sort; // Otherwise, compare based on the key.
    });
    return columns;
  };
  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    const updatedData = {
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    };
    if (columns.some(column => column.requiredIf && column.requiredIf.conditions && Array.isArray(column.requiredIf.conditions))) {
      columns.forEach(column => {
        if (column.requiredIf && column.requiredIf.conditions) {
          const conditionFields = column.requiredIf.conditions.map(condition => condition.field);
          if (conditionFields.includes(name)) {
            // Field that changed is part of the conditions in requiredIf
            const dependentFieldValue = updatedData[name];
            const matchingCondition = column.requiredIf.conditions.find(condition => condition.field === name);
            column.required = dependentFieldValue.toString() === matchingCondition.value.toString();
          }
        }
      });
    }
    setFormData(updatedData);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const sendData = { ...formData };
    // Convert multi-select arrays to comma-separated strings
    Object.keys(sendData).forEach((key) => {
      if (Array.isArray(sendData[key])) {
        sendData[key] = sendData[key].join(",");
      }
    });
    columns.forEach(column => {
      if (column.controlType === 'checkbox') {
        sendData[column.name] = sendData[column.name] ? 1 : 0;
      }
      else if (column.controlType === 'multiselect') {
        // Format as JSON array string
        sendData[column.name] = '[' + sendData[column.name] + ']';
      }
    });
    columns.forEach(column => {
      if (column.controlType === 'date') {
        sendData[column.name] = sendData[column.name] !== '' ? sendData[column.name] : null;
      }
    });

    const readOnlyColumns = columns.filter(column => column.read_only || column.ignore).map(column => column.name);
    readOnlyColumns.forEach(columnName => {
      if (columnName !== 'oid') {
        delete sendData[columnName];
      }
    });
    // const obj = { tableName: tableName, jsonObj: sendData, changeType: crudType }
    const responseData = await CentralCalls.upsertData(sendData, tableName, crudType);
    //*Potential problem here setting oid. Will set even if update. Should be the same but WATCH
    //console.log('params')
    //console.log(sendData);
    const updatedFormData = { ...formData, oid: responseData.oid };
    //CentralCalls.upsertData(insertObj, 'phase_status', 'insert');
    onSave(updatedFormData);
    if (crudType === 'insert') {

      const newFormData = {}; // Create a new object to store updated form data
      columns.forEach(column => {
        // console.log(column.retain_on_insert)
        if (column.retain_on_insert) {
          // Use computed property syntax to update the newFormData object
          newFormData[column.name] = sendData[column.name];
        }
      });
      setFormData(newFormData);
    }
  };
  const handleHeaderClick = (componentType) => {
    setDialogComponentType(componentType);
    setOpenDialog(true);
  };
  const handlePlanNumberClickPostRun = (row) => {
    options['plan_numbers'].push({ oid: row.oid, name: row.plan_number });
    formData['plan_number_oid'] = row.oid;
    setOpenDialog(false);
  }
  const handleAddNeighborhoodClickPostRun = (row) => {
    options['neighborhoods'].push({ oid: row.oid, name: row.name })
    formData['neighborhood_oid'] = row.oid;
    setOpenDialog(false);
  }
  const renderDialogComponent = () => {
    switch (dialogComponentType) {
      case 'plan_number':
        if (procedureDetails.parameters.builder_oid) {
          return <GenericInsert key={'/plan_numberIn'} displayName={'New Plan'} tableName={'plan_number'} onInsert={handlePlanNumberClickPostRun} defaults={[{ 'name': 'builder_oid', 'value': procedureDetails.parameters.builder_oid, 'enabled': false }]} />;
        } else {
          return <GenericInsert key={'/plan_numberIn'} displayName={'New Plan'} tableName={'plan_number'} onInsert={handlePlanNumberClickPostRun} />;
        }

      case 'neighborhood':
        if (procedureDetails.parameters.builder_oid) {
          return <GenericInsert key={'/neighborhoodIn'} displayName={'Neighborhood'} tableName={'neighborhood'} onInsert={handleAddNeighborhoodClickPostRun} defaults={[{ 'name': 'builder_oid', 'value': procedureDetails.parameters.builder_oid, 'enabled': false }]} />;
        } else {
          return <GenericInsert key={'/neighborhoodIn'} displayName={'Neighborhood'} tableName={'neighborhood'} onInsert={handleAddNeighborhoodClickPostRun} />;
        }


      default:
        return null;
    }
  };

  if (loading) {
    return <CircularProgress />;
  }
  //const color = theme?.header_back_color || 'primary';
  return (
    <>
      <Dialog fullWidth={true} maxWidth='lg' open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogContent style={{ overflow: 'visible' }}>
          {renderDialogComponent()}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          {columns.map((column) => (
            <React.Fragment key={column.name}>
              {!column.read_only && !column.hide && (
                <Grid item xs={12} sm={6} style={column.hide ? { visibility: 'hidden' } : { visibility: 'visible' }}>
                  <div className={classes.fieldContainer}>
                    {column.controlType === 'text' && (
                      <>
                        <TextField
                          fullWidth
                          required={column.required}
                          name={column.name}
                          label={column.display_name || column.name}
                          value={formData[column.name] !== undefined && formData[column.name] !== null ? formData[column.name] : column.default || ''}
                          type={column.type}
                          onChange={handleChange}
                        />
                      </>
                    )}
                    {column.controlType === 'number' && (
                      <>
                        <TextField
                          fullWidth
                          required={column.required}
                          name={column.name}
                          label={column.display_name || column.name}
                          value={formData[column.name] !== undefined && formData[column.name] !== null ? formData[column.name] : column.default || ''}
                          type="number"
                          onChange={handleChange}
                        />
                      </>
                    )}
                    {column.controlType === 'money' && (
                      <TextField
                        fullWidth
                        required={column.required}
                        name={column.name}
                        value={formData[column.name] !== undefined && formData[column.name] !== null ? formData[column.name] : column.default || ''}
                        label={column.display_name || column.name}
                        type="number"
                        onChange={handleChange}
                      />
                    )}
                    {column.controlType === 'date' && (
                      <TextField
                        fullWidth
                        required={column.required}
                        name={column.name}
                        label={column.display_name || column.name}
                        value={formData[column.name] || column.default || ''}
                        type={'date'}
                        onChange={handleChange}
                        InputLabelProps={{ shrink: true }}
                      />

                    )}
                    {column.controlType === 'checkbox' && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            name={column.name}
                            checked={!!formData[column.name] || !!column.default}
                            onChange={handleChange}
                            color="primary"
                          />
                        }
                        label={column.display_name || column.name}
                      />
                    )}
                    {column.controlType === 'autocomplete' && (
                      <Autocomplete
                        id={column.name}
                        options={options[column.options_name]?.sort((a, b) => a.name.localeCompare(b.name)) || []}  // Fallback to empty array
                        getOptionLabel={(option) => option.name}
                        style={{ width: '100%' }}
                        onChange={(event, newValue) => {
                          // Create synthetic event object
                          const syntheticEvent = {
                            target: {
                              name: column.name,
                              value: newValue ? newValue.oid : '',
                              type: 'autocomplete',
                            },
                          };
                          handleChange(syntheticEvent);
                        }}
                        value={
                          (options[column.options_name] || []).find(
                            option => option.oid === (formData && formData[column.name]) || option.oid === column.default
                          ) || null  // Fallback to null
                        }
                        renderInput={(params) => (
                          <FormControl fullWidth required={column.required}>
                            <TextField
                              {...params}
                              label={column.display_name || column.name}
                              variant="standard"
                              required={column.required}
                            />
                          </FormControl>
                        )}
                      />
                    )}
                    {column.controlType === 'list' && (
                      <FormControl fullWidth required={column.required}>
                        <InputLabel id={`${column.name}-label`}>
                          {column.display_name || column.name}
                        </InputLabel>
                        <Select
                          labelId={`${column.name}-label`}
                          id={column.name}
                          name={column.name}
                          value={(formData && formData[column.name]) || column.default || ''}

                          onChange={handleChange}
                        >
                          {options[column.options_name] &&
                            options[column.options_name]
                              .sort((a, b) => a.name.localeCompare(b.name)) // Sort options inline
                              .map((option) => (
                                <MenuItem key={option.oid} value={option.oid}>
                                  {option.name}
                                </MenuItem>
                              ))}
                        </Select>
                      </FormControl>
                    )}
                    {column.controlType === 'radio' && (
                      <FormControl >
                        <FormLabel component="legend">{column.display_name}</FormLabel>
                        <RadioGroup
                          name={column.name}
                          value={formData[column.name] || column.default || ''}
                          onChange={handleChange}
                          row >
                          {options[column.list_name] &&
                            options[column.list_name].map((option) => (
                              <FormControlLabel
                                key={option.oid}
                                value={option.oid.toString()}
                                control={<Radio color="primary" required={column.required} />}
                                label={option.name}
                              />
                            ))}
                        </RadioGroup>
                      </FormControl>
                    )}
                    {column.controlType === 'multiselect' && (
                      <FormControl fullWidth required={column.required}>
                        <InputLabel id={`${column.name}-label`}>
                          {column.display_name || column.name}
                        </InputLabel>
                        <Select
                          name={column.name}
                          multiple
                          value={formData[column.name] || []}
                          onChange={handleChange}
                        >
                          {options[column.list_name] &&
                            options[column.list_name]
                              .slice()
                              .sort((a, b) => a.name.localeCompare(b.name)) // Sort options inline
                              .map((option) => (
                                <MenuItem key={option.oid} value={option.oid}>
                                  {option.name}
                                </MenuItem>
                              ))}
                        </Select>
                      </FormControl>
                    )}
                    {column.clickable === 1 && (
                      <IconButton onClick={() => handleHeaderClick(column.click_component)}>
                        {column.icon === 'add' ? <AddIcon /> : <EventIcon />}
                      </IconButton>
                    )}
                  </div>
                </Grid>
              )}
              {column.read_only && !column.hide && (
                <Grid item xs={12} sm={6}>
                  <InputLabel
                    htmlFor={column.name}
                    sx={{ textDecoration: 'underline', textUnderlineOffset: '4px' }} // Custom underline styling
                  >
                    {column.display_name || column.name}
                  </InputLabel>
                  {column.controlType !== 'checkbox' && (
                  <Typography variant="body1" gutterBottom label={column.display_name || column.name}>
                    {/* <strong>
                      {column.display_name || column.name}:
                    </strong> */}
                    {formData[column.name] || ''}
                  </Typography>)}
                  {column.controlType === 'checkbox' && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          name={column.name}
                          checked={!!formData[column.name] || !!column.default}
                          onChange={handleChange}
                          color="primary"
                          disabled={true}
                        />
                      }
                      label={''}
                    />
                  )}
                </Grid>
              )}
            </React.Fragment>
          ))}
          <Grid item xs={12}>
            {!displayOnly &&
              (<Button variant="contained" style={{ backgroundColor: theme?.header_back_color ? theme.header_back_color : '', color: theme?.header_text_color ? theme.header_text_color : '' }} type="submit">
                {saveName ? saveName : 'Save'}
              </Button>)
            }

          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default DynamicForm;

import { React, useState, useEffect } from 'react';
import JSONTableEditor from '../JSONTableEditor';
import DynamicForm from '../DynamicForm';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, IconButton, Tooltip, MenuItem, Select } from '@mui/material';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { makeStyles } from '@mui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LotFilter from '../workInProgress/LotFilter';
import { useTheme } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import CentralCalls from '../../centralCalls';
import ButtonMenu from '../ButtonMenu';

import PurchaseReady from './PurchaseReady';
const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
        marginBottom: theme.spacing(3),
    },
    select: {
        minWidth: 200,
    },
    accordion: {
        borderRadius: '15px',
        margin: '10px 0',
        '&:before': {
            display: 'none',
        },
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:after': {
            display: 'none',
        },
    },
    accordionSummary: {
        backgroundColor: 'rgba(0, 0, 0, .03)',
    },
}));
const BasePurchase = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const menuItems = [{order:1, name_id: 'open', display_name :'Ready To Order'},{order:2, name_id: 'ordered', display_name :'Ordered'}, {order:3, name_id: 'complete', display_name:'Pulled'}, {order:4, name_id: 'deleted', display_name:'Deleted'}]
    const [AddPODialogOpen, setAddPODialogOpen] = useState(false);
    const [selectedMenuItemNameId, setSelectedMenuItemNameId] = useState('open');
    const [selectedMenuItemName, setSelectedMenuItemName] = useState('Open');
    const [selectedMenuItem, setSelectedMenuItem] = useState(menuItems[0]);
    const [selectedNextMenuItem, setSelectedNextMenuItem] = useState(menuItems[1]);
    const openCustomPO = async () => {
        setAddPODialogOpen(true);
    }
    const handlePostInsertPo = async (poRow) => {
        setAddPODialogOpen(false);
    }
    const menuItemSelectected = (menuItem) => {
        setSelectedMenuItemNameId(menuItem.name_id);
        setSelectedMenuItemName(menuItem.display_name);
        setSelectedMenuItem(menuItem);

        if(menuItem.name_id === 'open'){
            setSelectedNextMenuItem(menuItems[1]);
        }
        else if(menuItem.name_id === 'ordered'){
            setSelectedNextMenuItem(menuItems[2]);
        }
       
    }


    return (
        <>
        <h1>Purchasing
                {/* <Tooltip title="Add PO">
                    <IconButton color="primary" aria-label="save" onClick={openCustomPO} size="medium">
                        <FontAwesomeIcon icon={faPlus} />
                    </IconButton>
                </Tooltip> */}
            </h1>
            <ButtonMenu onMenuSelect={menuItemSelectected} menuItems={menuItems} defaultMenuItem={menuItems[0]} ></ButtonMenu>
            <PurchaseReady currentOrderStatus={selectedMenuItem.name_id} currentOrderStatusDisplay={selectedMenuItem.display_name} newOrderStatus={selectedNextMenuItem.name_id} orderStatusDisplay={selectedNextMenuItem.display_name} ></PurchaseReady>
       
            <Dialog fullWidth={true} maxWidth='lg' open={AddPODialogOpen} onClose={() => setAddPODialogOpen(false)}>
                <DialogTitle><h1>New Order</h1></DialogTitle>
                <DialogContent >
                                      
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setAddPODialogOpen(false)} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

        </>
    );
};

export default BasePurchase;
import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import { Dialog, DialogActions, DialogContent, IconButton} from '@mui/material';
import { DateRangePicker } from 'react-date-range';
import { enUS } from 'date-fns/locale';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { createStaticRanges } from 'react-date-range/dist/defaultRanges';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';
import CentralCalls from '../../centralCalls';
import { Badge } from '@mui/material';

const ActivePhaseButtonGroup = ({ onFilterSelect, theme, phaseType, refreshMenuKey }) => {
  const [activeFilter, setActiveFilter] = useState({ name_id: 'open', name: 'Open' });
  const [dialogOpen, setDialogOpen] = useState(false);
  const [startDate, setStartDate] = useState(new Date(new Date().setDate(new Date().getDate() - 30)));
  const [endDate, setEndDate] = useState(new Date());
  const [statsData, setStatsData] = useState({});
  
  const phaseTypes = [{ name_id: 'open', name: 'Open', hideCalendar : true}, { name_id: 'scheduled', name: 'Scheduled', hideCalendar : true }, { name_id: 'upcoming', name: 'Upcoming', hideCalendar : true }, { name_id: 'complete', name: 'Complete' }, { name_id: 'hold', name: 'On Hold' }, { name_id: 'deleted', name: 'Deleted' }];
console.log('menukey:' + refreshMenuKey)
  useEffect(() => {
    console.log('running')
   // console.log('start' + startDate + '... end')
    console.log({'start_date': CentralCalls.sqlFormatdate(startDate), 'end_date' : CentralCalls.sqlFormatdate(endDate)});
    CentralCalls.callStoredProcedureWithParams('sp_getPhaseMenuTotals', { 'phase_type' : phaseType, 'start_date': CentralCalls.sqlFormatdate(startDate), 'end_date' : CentralCalls.sqlFormatdate(endDate)}).then(response => {
      // Check if the inspection object is already in the array
      setStatsData(response);
      console.log('statsdata...');
      console.log(response);
    });
  }, [activeFilter, startDate, endDate, phaseType, refreshMenuKey]);
  const handleButtonClick = (filter) => {
    setActiveFilter(filter);
    onFilterSelect({ filter:filter, startDate : startDate, endDate :endDate});
  };

  const isActive = (filter) => filter.name_id === activeFilter.name_id;
  const formatDate = (date) => {
    if (!date) return '';
    return format(date, 'MM/dd/yyyy');
  };
  const handleClose = () => {
    setDialogOpen(false);
  };
  const handleDateChange = (item) => {
    const newStartDate = item.selection.startDate;
    const newEndDate = item.selection.endDate;
    setStartDate(newStartDate);
    setEndDate(newEndDate);
    // Now using the newStartDate and newEndDate directly
    onFilterSelect({ filter: activeFilter, startDate: newStartDate, endDate: newEndDate });
  };

  return (
    <>
   {!activeFilter.hideCalendar ? (
      <>
        <IconButton aria-label="save" onClick={() => setDialogOpen(true)} size="medium">
          <FontAwesomeIcon icon={faCalendar} />
        </IconButton>
        {formatDate(startDate)} - {formatDate(endDate)}
        <br/>
      </>
    ) : null}
      <ButtonGroup variant="contained" sx={{ boxShadow: 'none', border: 'none' }}>
        {phaseTypes.map((phase) => (
              <Badge
              key={phase.name_id}
              showZero
              max={999}
              badgeContent={(statsData[phase.name_id] ?? 0) > 999 ? '999+' : (statsData[phase.name_id] ?? 0)}
              color="secondary"
              sx={{
                '& .MuiBadge-badge': {
                  top: '0%',
                  right: -3,
                  backgroundColor:!isActive(phase) ? theme?.main_back_color : theme?.header_back_color,
                  color: !isActive(phase) ? theme?.header_back_color : theme?.header_text_color,
                  // Customize the badge's position and appearance as needed
                },
              }}
            >
            
          <Button
            key={phase.name_id}
            onClick={() => handleButtonClick(phase)}
            
            sx={{
              borderRadius: '15px',
              border: !isActive(phase) ? 'none' : 'solid',
              fontSize: '10.5px',
              marginLeft:'15px',
              width:'100px',
              fontWeight: isActive(phase) ? 'bold' : 'normal',
              backgroundColor: isActive(phase) ? theme?.main_back_color : theme?.header_back_color,
              color: isActive(phase) ? theme?.header_back_color : theme?.header_text_color,
              '&:hover': {
                backgroundColor: isActive(phase) ? theme?.main_back_color : theme?.header_back_color,
                color: isActive(phase) ? 'black' : 'whitesmoke',
              },
            }}>
            {phase.name}
          </Button>
          </Badge>
        ))}
      </ButtonGroup>
          
    
      <Dialog open={dialogOpen}  onClose={handleClose}>
        <DialogContent  style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '400px', 
            marginLeft: '-250px'// Adjust height based on your needs
          }} >
        <DateRangePicker
          onChange={handleDateChange}
          showSelectionPreview={true}
          moveRangeOnFirstSelection={false}
          months={1}
          direction="horizontal"
          locale={enUS}
          ranges={[{
            startDate: startDate,
            endDate: endDate,
            key: 'selection',
          }]}
          inputRanges={[]}
        />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Ok</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ActivePhaseButtonGroup;
